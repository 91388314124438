<template>
    <ins class="adsbygoogle"
        style="display:block; width:100%;"
        :data-ad-client="client"
        :data-ad-slot="adSlot"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
</template>

<script>
export default {
    name: 'Ad',
    props: ['adName', 'adSlot'],
    setup () {
        const client = "ca-pub-6906936642449515";

        return {client}
    }
}
</script>

<style lang="scss" scoped>
.adsbygoogle{
    // background: red;
}
</style>